<template>
  <div>
    <b-modal
      id="widgetModal"
      :visible="showModalWidget"
      centered
      ok-only
      :title="$t('add-widget')"
      no-close-on-backdrop
      :ok-title="$t('buttons.save')"
      @close="handleClose()"
    >
      <validation-observer
        ref="formWidget"
        v-slot="{ }"
      >
        <b-form>

          <!-- Router -->
          <b-form-group>
            <!-- <label for="name">Sección <span class="text-danger">*</span></label> -->
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="title"
            >
              <ul>
                <li
                  v-for="(item) in widgetList.lg"
                  :key="item.i"
                >
                  <input
                    :id="'cb' + item.i"
                    type="checkbox"
                    :checked="item.status"
                    :value="1"
                    :unchecked-value="0"
                    @change="addWidget(item)"
                  >
                  <label :for="'cb' + item.i"><img :src="'/'+item.img"></label>
                  <label> {{ $t(item.title) }} </label>
                </li>
              </ul>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- {{userWidgetList}} -->
        </b-form>
      </validation-observer>

      <template #modal-footer>
        <div class="float-right">
          <b-button
            variant="secondary"
            pill
            @click="handleClose"
          >
            <!-- @click="resetForm" -->
            {{ $t('buttons.cancel') }}
          </b-button>

          <b-button
            variant="primary"
            style="margin-left: 17px;"
            pill
            :disabled="userWidgetList.length == 0"
            :style="colorPrimaryBtn"
            @click="handleAddWidget"
          >
            {{ $t('buttons.save') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import checkPermission from '@/auth/permissions'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // DirectAccessCard,
  },
  props: {
    showModalWidget: {
      type: Boolean,
      defalut: false,
    },
    widgetList: {
      type: [Array, Object],
      default: null,
    },
    userWidgetList: {
      type: [Array, Object],
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    //
  },
  watch: {
  },
  mounted() {
    //
  },
  methods: {
    checkPermission,
    // getImgUrl(pet) {
    //   // var images = require(pet)
    //   return require(`@${pet}`)
    // },
    handleClose() {
      this.$emit('show-modal', false, false)
    },
    handleAddWidget() {
      this.$refs.formWidget.validate().then(isValid => {
        if (isValid) {
          this.$emit('add-widget', this.userWidgetList)
          this.handleClose()
        }
      })
    },
    addWidget(item) {
      // En la otra lista tambien traer todo
      const element = this.userWidgetList.lg.find(e => e.i == item.i)
      if (element != null) {
        const indexlg = this.userWidgetList.lg.findIndex(ele => ele.i === item.i)
        this.userWidgetList.lg.splice(indexlg, 1)
        const indexmd = this.userWidgetList.md.findIndex(ele => ele.i === item.i)
        this.userWidgetList.md.splice(indexmd, 1)
        const indexxxs = this.userWidgetList.xxs.findIndex(ele => ele.i === item.i)
        this.userWidgetList.xxs.splice(indexxxs, 1)
      } else {
        const elementLg = this.widgetList.lg.find(e => e.i == item.i)
        const elementMd = this.widgetList.md.find(e => e.i == item.i)
        const elementXxs = this.widgetList.xxs.find(e => e.i == item.i)
        this.userWidgetList.lg.push(elementLg)
        this.userWidgetList.md.push(elementMd)
        this.userWidgetList.xxs.push(elementXxs)
      }
    },
    // clearForm() {
    //   this.form = {
    //     id: null,
    //     color: null,
    //   }
    // },
  },
}
</script>
<style scoped lang="scss">
// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';
ul {
  list-style-type: none;
}

li {
  display: inline-table;
}

input[type="checkbox"][id^="cb"] {
  display: none;
}

label {
  width: 100px;
  border: 1px solid #fff;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 0;
  padding-left: 10px;
  display: block;
  position: relative;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  cursor: pointer;
}

label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: none;
  position: absolute;
  top: -3px;
  left: -3px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

label img {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

// :checked + label {
//   border-color: #ddd;
// }

:checked + label:before {
  content: "✓";
  background-color: $primary;
  transform: scale(1);
  z-index: 5;
}

:checked + label img {
  transform: scale(0.9);
  box-shadow: 0 0 5px $secondary;
  z-index: -1;
}
</style>
