<template>
  <!-- v-if="data" -->
  <b-overlay
    :show="isLoading"
  >
    <!-- :class="applyOverlayPOA ? 'p-2 mb-1' : 'mb-1'" -->
    <template #overlay>
      <div class="d-flex align-items-center justify-content-center mt-1">
        <b-spinner
          type="border"
          variant="primary"
        />
      </div>
      <div class="d-flex justify-content-center">
        <p class="pt-1">
          {{ $t('loading') }}
        </p>
      </div>
    </template>
      <!-- class="card-statistics" -->
    <b-card
      no-body
      :class="isDay ? 'day' : 'night'"
    >
      <div class="">
        <div
          v-if="clearSky"
          icon="sunny"
          data-label="Sunny"
        >
          <span class="sun" />
        </div>

        <div
          v-if="snowy"
          icon="snowy"
          data-label="Chilly"
        >
          <ul>
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
        </div>

        <div
          v-if="stormy"
          icon="stormy"
          data-label="Soggy"
        >
          <span class="cloud" />
          <ul>
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
        </div>
        <div
          v-if="cloudy"
          icon="cloudy"
          data-label="Perfect"
        >
          <!-- <span class="cloud" /> -->
          <!-- <span class="cloud" /> -->
          <span class="sun" />
        </div>
        <div
          v-if="clearNight"
          icon="nightmoon"
          data-label="Cool!"
        >
          <span class="moon" />
          <span class="meteor" />
        </div>
      </div>
      <b-card-header class="mb-0 pb-0">
        <b-card-title class="text-white">
          {{ weather.cityName }}
        </b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0 text-white">
          {{ weather.country }}
        </b-card-text>
      </b-card-header>
      <b-card-body class="text-white text-center">
        <b-row>
          <b-col
            cols="12"
          >
            <!-- :class="item.customClass" -->
            <b-media no-body>
              <b-media-body class="my-auto">
                <h5 class="font-weight-normal mb-0 temp text-white">
                  <span>{{ weather.temperature }}&deg;C</span>
                </h5>
                <b-card-text class="font-small-3 mb-0 text-capitalize">
                  {{ weather.description }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            cols="6"
            xl="6"
            lg="6"
            md="6"
            sm="6"
          >
            <b-media no-body>
              <b-media-body class="my-auto">
                <h4 class="text-white mt-1 mb-0">
                  <span>{{ weather.feelsLike }}&deg;C</span>
                </h4>
                <span class="font-small-3">{{ $t('sensation') }}</span>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            cols="6"
            xl="6"
            lg="6"
            md="6"
            sm="6"
          >
            <b-media no-body>
              <b-media-body class="my-auto">
                <h4 class="text-white mt-1 mb-0">
                  <span>{{ weather.humidity }}%</span>
                </h4>
                <span class="font-small-3">{{ $t('humidity') }}</span>
              </b-media-body>
            </b-media>
          </b-col>

          <!-- Min -->
          <b-col
            cols="6"
            xl="6"
            lg="6"
            md="6"
            sm="6"
            class="mb-1"
          >
            <b-media no-body>
              <b-media-body class="my-auto">
                <h4 class="text-white mt-1 mb-0">
                  <span>{{ weather.lowTemp }}&deg;C</span>
                </h4>
                <span class="font-small-3">{{ $t('minimum') }}</span>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            cols="6"
            xl="6"
            lg="6"
            md="6"
            sm="6"
            class="mb-1"
          >
            <b-media no-body>
              <b-media-body class="my-auto">
                <h4 class="text-white mt-1 mb-0">
                  <span>{{ weather.highTemp }}&deg;C</span>
                </h4>
                <span class="font-small-3">{{ $t('maximum') }}</span>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>

export default {
  data() {
    return {
      isLoading: false,
      cityFound: false,
      visible: false,
      stormy: false,
      cloudy: false,
      clearSky: false,
      clearNight: false,
      snowy: false,

      isDay: true,
      weather: [],
    }
  },
  mounted() {
    this.getWeather()
  },
  methods: {
    async getWeather() {
      this.isLoading = true
      this.$emit('handle-loading', true)
      // const key = process.env.VUE_APP_API_KEY
      const baseURL = 'https://api.openweathermap.org/data/2.5/weather?lat=19.5473579&lon=-99.1391088&appid=375b5b72defecfdfccfa090d50f49db4&lang=es&units=metric'

      // fetch weather
      try {
        const response = await fetch(baseURL)
        const data = await response.json()
        this.citySearch = ''
        this.weather.cityName = data.name
        this.weather.country = data.sys.country
        this.weather.temperature = Math.round(data.main.temp)
        this.weather.description = data.weather[0].description
        this.weather.lowTemp = Math.round(data.main.temp_min)
        this.weather.highTemp = Math.round(data.main.temp_max)
        this.weather.feelsLike = Math.round(data.main.feels_like)
        this.weather.humidity = Math.round(data.main.humidity)

        const timeOfDay = data.weather[0].icon

        /// check for time of day
        if (timeOfDay.includes('n')) {
          this.isDay = false
        } else {
          this.isDay = true
        }

        const mainWeather = data.weather[0].main
        // check weather animations
        if (mainWeather.includes('Clouds')) {
          this.stormy = false
          this.cloudy = true
          this.clearSky = false
          this.clearNight = false
          this.snowy = false
        }
        if (mainWeather.includes('Clouds')) {
          this.stormy = false
          this.cloudy = true
          this.clearSky = false
          this.clearNight = false
          this.snowy = false
        }
        if (
          mainWeather.includes('Thunderstorm')
          || mainWeather.includes('Rain')
        ) {
          this.stormy = true
          this.cloudy = false
          this.clearSky = false
          this.clearNight = false
          this.snowy = false
        }
        if (mainWeather.includes('Clear') && this.isDay) {
          this.stormy = false
          this.cloudy = false
          this.clearSky = true
          this.clearNight = false
          this.snowy = false
        }
        if (mainWeather.includes('Clouds') && !this.isDay) {
          this.stormy = false
          this.cloudy = false
          this.clearSky = false
          this.clearNight = true
          this.snowy = false
        }
        if (mainWeather.includes('Snow')) {
          this.stormy = false
          this.cloudy = false
          this.clearSky = false
          this.clearNight = false
          this.snowy = true
        }

        this.visible = true
        this.cityFound = false
        this.isLoading = false
        this.$emit('handle-loading', false)
      } catch (error) {
        this.cityFound = true
        this.visible = false
        this.isLoading = false
        this.$emit('handle-loading', false)
      }
    },
  },
}
</script>

<style scoped lang="css">
@import '../../assets/weather/custom.css';
@import '../../assets/weather/animation.css';
.card-statistics .statistics-body {
  padding-top: 0rem !important;
  padding-right: 2.4rem !important;
  padding-bottom: 1rem !important;
  padding-left: 2.4rem !important;
}
.card-statistics {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 10px !important;
}
.card {
  height: 95%;
}
</style>
