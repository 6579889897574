<template>
  <!-- Click-only FAB html from http://materializecss.com/buttons.html  -->
  <div>
    <div class="container">
      <input
        id="toggle"
        v-model="checkedBtn"
        type="checkbox"
      >
      <label
        class="button"
        for="toggle"
        :style="colorPrimaryBtn"
      />
      <nav
        v-if="checkedBtn"
        class="nav"
      >
        <ul class="options">
          <li>
            <input
              id="is-draggable"
              v-model="isDraggable"
              type="checkbox"
              :value="true"
              :unchecked-value="false"
              @change="handleDraggble(isDraggable)"
            >
            <label
              v-b-tooltip.hover.left="$t('adminWidget')"
              class="iconbutton cursor-pointer"
              for="is-draggable"
            ><i class="icon-0-icons-dark-fullscreen" /></label>
          </li>
          <li>
            <input
              id="direct-access-modal"
              v-model="auxModal"
              type="checkbox"
              :value="true"
              :unchecked-value="false"
              @change="handleDirectAccess(auxModal)"
            >
            <label
              v-b-tooltip.hover.left="$t('addDirectAccess')"
              class="iconbutton cursor-pointer"
              for="direct-access-modal"
            ><i class="icon-0-icons-dark-bookmark" /></label>
          </li>
          <!-- widget -->
          <li>
            <input
              id="widget-modal"
              v-model="auxWidget"
              type="checkbox"
              :value="true"
              :unchecked-value="false"
              @change="handleWidget(auxWidget)"
            >
            <label
              v-b-tooltip.hover.left="$t('addWidget')"
              class="iconbutton cursor-pointer"
              for="widget-modal"
            ><i class="icon-0-icons-dark-desktop" /></label>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    showModalWidget: {
      type: Boolean,
      default: false,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      auxModal: false,
      auxWidget: false,
      isDraggable: false,
      isResizable: false,
      checkedBtn: false,
      checkedBtnWidget: {
        status: false,
      },
    }
  },

  watch: {
    showModal(pShowM) {
      this.auxModal = pShowM
      this.isDraggable = false
    },
    showModalWidget(pShowW) {
      this.auxWidget = pShowW
      this.isDraggable = false
    },
  },

  methods: {
    handleDraggble(value) {
      this.$emit('handle-draggable', value)
      this.$emit('handle-resizable', value)
    },
    handleDirectAccess(value) {
      this.auxModal = value
      this.$emit('handle-direct-access-modal', value, true)
    },
    handleWidget(value) {
      this.auxWidget = value
      this.$emit('handle-widget-modal', value, false)
    },
  },

}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto");

$purple: #59637f;
$light-purple: #b3bcc7;

// body {
//   background: #fafafa;
//   display: flex;
//   justify-content: end;
//   align-items: end;
//   height: 100vh;
// }
input[type=checkbox] {
  height: 0em;
  width: 0em;
  background: none;
  display: none;
  cursor: pointer;
}
#is-draggable, input[type=checkbox] + label, .iconbutton {
  background: #6abeff;
}
#is-draggable, input[type=checkbox]:checked + label, .iconbutton {
  background: #b8c5e6;
}
.container {
  width: 20%;
  // height: 300px;
  display: flex;
  justify-content: end;
  align-items: end;
  position: fixed;
  right: 35px;
  z-index: 999;
  bottom: 0;
}

#toggle {
  -webkit-appearance: none;
}

.button {
  position: fixed;
  z-index: 999;
  width: 39px;
  height: 39px;
  background: $purple;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  // top: -12px;
  bottom: 0;
  right: 0;
  margin: 65px 55px;

  &:before {
    position: absolute;
    content: "";
    width: 20px;
    height: 2px;
    background: #fff;
    transform: rotate(90deg);
    transition: all 0.4s ease;
  }
  &:after {
    position: absolute;
    content: "";
    width: 20px;
    height: 2px;
    background: #fff;
    transition: all 0.4s ease;
  }
}

.nav {
  opacity: 0;
  transition: all 0.8s ease;
  // background: rgb(209, 13, 13);
  width: 100%;
  border-radius: 5px;
  transform: translateY(0%);
  box-shadow: 2px 3px 10px 0 rgba(81, 81, 81, 0.1);
  border: 1px solid #e4e4e4;
  padding: 10px;
  // height: 43px;
  position: absolute;
  z-index: 100;

  ul {
    margin-bottom: 35px;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  li {
    text-align: center;
    margin: 0;
    color: $purple;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    &:hover {
      color: $light-purple;
    }
  }
}
// #toggle:checked .nav {
//   height: 300px;
// }
#toggle:checked ~ .nav {
  opacity: 1;
  // transform: translateY(300px);
}

#toggle:checked ~ .button:before {
  transform: rotate(225deg);
}

#toggle:checked ~ .button:after {
  transform: rotate(135deg);
}

@media (max-width: 640px) {
  .container {
    width: 100%;
  }
}

// TEst
.iconbutton {
  width: 43px;
  height: 43px;
  border-radius: 100%;
  background: #98a1b3;
  box-shadow: 5px 5px 5px #e0dede;
}
.iconbutton i {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
}

.options li {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.options {
  list-style-type: none;
  position: absolute;
  bottom: 70px;
  right: 0;
}

.btn-label {
  padding: 2px 5px;
  margin-right: 10px;
  align-self: center;
  user-select: none;
  background-color: black;
  color: white;
  border-radius: 3px;
  box-shadow: 5px 5px 5px #e0dede;
}
</style>
