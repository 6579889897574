<template>
  <b-card
    no-body
    class="card-developer-meetup bg-tickets-sale"
  >
    <div>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          class="text-center"
        >
          <h3 class="mt-1 text-white">
            {{ $t('tickets-sale') }}
          </h3>
          <h1 class="m-1 text-white">
            ${{ amount }} &nbsp; &nbsp;
            <feather-icon
              icon="ArrowUpIcon"
              size="18"
            />
            +{{ salesIncreaseRate }}%
          </h1>
        </b-col>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-img
            class="image-tickets-sale-stadium"
            :src="require('@/assets/images/dashboard/ticketsSaleStadium.png')"
            alt="OStrail image sales"
          />
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>

export default {
  name: 'TicketsSale',
  components: {
  },
  data() {
    return {
      amount: '2,8000.00',
      salesIncreaseRate: '2',
    }
  },
}
</script>
<style lang="scss" scoped>
.image-tickets-sale-stadium {
  width: 200px;
  height: 85px;
}
.bg-tickets-sale {
  background-image: linear-gradient(to right, #FF6693 0%, #5127DD);
}
</style>
