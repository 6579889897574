import { render, staticRenderFns } from "./GoalsAgainstCard.vue?vue&type=template&id=22be4698&scoped=true&"
import script from "./GoalsAgainstCard.vue?vue&type=script&lang=js&"
export * from "./GoalsAgainstCard.vue?vue&type=script&lang=js&"
import style0 from "./GoalsAgainstCard.vue?vue&type=style&index=0&id=22be4698&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22be4698",
  null
  
)

export default component.exports