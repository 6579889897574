<template>
  <!--  min-width: 100px; -->
  <b-card-access-actions
    style="height: 85%; width: 100%"
    action-close
    :collapsed="true"
    :no-actions="noAction"
    @close="onDelete(id)"
  >
    <div @click="goRoute(route)">
      <b-row>
        <b-col
          cols="4"
          lg="4"
          md="4"
          sm="4"
          class="text-center p-0 cursor-pointer"
        >
          <b-avatar
            :variant="`light-${color}`"
            size="35"
            style="font-size: 1.2rem;"
          >
            <i :class="icon" />
            <!-- <feather-icon
              size="21"
              :icon="icon"
            /> -->
          </b-avatar>
        </b-col>
        <b-col
          cols="8"
          lg="8"
          md="8"
          sm="8"
          class="p-0 cursor-pointer"
        >
          <div class="truncate">
            <h3 :class="{'font-weight-normal': shortTitle(), 'font-card': !shortTitle() }">
              {{ title }}
            </h3>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card-access-actions>
</template>

<script>
import BCardAccessActions from '../../components/b-card-access-actions/BCardActions.vue'

export default {
  components: {
    BCardAccessActions,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: [Number, String],
      required: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
    noAction: {
      type: Boolean,
      default: true,
    },
    route: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // shortTitle: true,
    }
  },
  methods: {
    onDelete(id) {
      this.$emit('handle-delete', id)
    },
    goRoute(route) {
      if (route != null) {
        this.$router.push(route)
      }
    },
    shortTitle() {
      if (this.title.length > 10) {
        return false
      }
      // {{ item.title.substring(0,55)+"..." }}
      return true
    },
  },
}
</script>
<style scoped>
.font-card {
  font-size: 16px;
}
</style>
