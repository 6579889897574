<template>
  <div>

    <!-- <direct-access-card
      color="primary"
      icon="icon-0-icons-dark-plus"
      :title="$t('generic.add')"
      :no-action="true"
    /> -->
    <b-modal
      id="createDirectAccessModal"
      :visible="showModalAccessDirect"
      centered
      ok-only
      :title="$t('addDirectAccess')"
      no-close-on-backdrop
      :ok-title="$t('buttons.save')"
      @close="handleClose()"
    >
      <validation-observer
        ref="formDirectAccess"
        v-slot="{ }"
      >
        <b-form>

          <!-- Router -->
          <b-form-group>
            <label for="name">{{ $t('generic.section') }} <span class="text-danger">*</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="title"
            >
              <v-select
                v-model="form"
                label="title"
                :placeholder="$t('select-module')"
                :options="listSecctions"
                :reduce="(item) => item"
              >
                <div slot="no-options">
                  {{ $t('generic.noRecordsFound') }}
                </div>
              </v-select>
              <small class="text-danger">{{ equalSection }}</small>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Color -->
          <b-form-group>
            <label for="name">{{ $t('color') }} <span class="text-danger">*</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="color"
            >
              <v-select
                v-model="form.color"
                label="name"
                :placeholder="$t('select-module')"
                :options="variants"
                :reduce="(item) => item.name"
              >
                <template #option="{ name }">
                  <span
                    class="bullet bullet-sm mr-1"
                    :class="`bullet-${name}`"
                  />
                  <span class="ml-50 d-inline-block align-middle"> {{ $t(name) }}</span>
                </template>

                <template #selected-option="{ name }">
                  <span
                    class="bullet bullet-sm mr-1"
                    :class="`bullet-${name}`"
                  />

                  <span class="ml-50 d-inline-block align-middle"> {{ $t(name) }}</span>
                </template>

                <!-- Not item found -->
                <div slot="no-options">
                  {{ $t('generic.noRecordsFound') }}
                </div>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>

      <template #modal-footer>
        <div class="float-right">
          <b-button
            variant="secondary"
            pill
            @click="handleClose"
          >
            <!-- @click="resetForm" -->
            {{ $t('buttons.cancel') }}
          </b-button>

          <b-button
            variant="primary"
            style="margin-left: 17px;"
            pill
            :style="colorPrimaryBtn"
            :disabled="equalSection != ''"
            @click="handleDirectAccess"
          >
            {{ $t('buttons.save') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import checkPermission from '@/auth/permissions'
import searchAndBookmarkData from '@/@core/layouts/components/app-navbar/search-and-bookmark-data'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import DirectAccessCard from './DirectAccessCard.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // DirectAccessCard,
    vSelect,
  },
  props: {
    showModalAccessDirect: {
      type: Boolean,
      defalut: null,
    },
    listDirectAccess: {
      type: Array,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      variants: [{ name: 'dark' }, { name: 'primary' }, { name: 'secondary' }, { name: 'success' }, { name: 'danger' }, { name: 'warning' }, { name: 'info' }],
      listSecctions: [],
      equalSection: null,
      form: {
        title: '',
        color: null,
      },
    }
  },
  watch: {
    form(val) {
      const result = this.listDirectAccess.find(item => item.title === val.title)
      if (result) {
        this.equalSection = this.$t('registered-shortcut')
      } else {
        this.equalSection = ''
      }
    },
  },
  mounted() {
    const { data } = searchAndBookmarkData.pages
    this.listSecctions = data.filter(item => checkPermission(item.permission) && item.locale === this.$i18n.locale)
  },
  methods: {
    checkPermission,
    handleClose() {
      this.$emit('show-modal', false, true)
      this.clearForm()
    },
    handleDirectAccess() {
      this.$refs.formDirectAccess.validate().then(isValid => {
        if (isValid) {
          this.$emit('add-direct-access', this.form)
          this.handleClose()
          this.clearForm()
        }
      })
    },
    clearForm() {
      this.form = {
        title: null,
        color: null,
      }
    },
  },
}
</script>
<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.mr-20 {
  margin-right: 20px !important;
}
</style>
