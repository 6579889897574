<template>
  <div>
    <b-row>
      <b-col
        v-for="itemDA in listDirectAccess"
        :key="itemDA.hash"
        class="cursor-pointer card-direct"
      >
        <direct-access-card
          :id="itemDA.hash"
          :color="itemDA.color"
          :icon="itemDA.icon"
          :title="itemDA.title"
          :route="itemDA.route"
          :no-action="!draggable"
          @handle-delete="onDelete"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import DirectAccessCard from './DirectAccessCard.vue'

export default {
  name: 'DirectAccessGroupCard',
  components: {
    DirectAccessCard,
  },
  props: {
    listDirectAccess: {
      type: Array,
      default: null,
    },
    draggable: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      // property: 'value',
    }
  },
  methods: {
    ...mapActions({
      removeData: 'dashboard/removeDirectAccess',
      fetchDataDirectAccess: 'dashboard/fetchDataDirectAccess',
      fetchDataWidgetConfigurations: 'dashboard/fetchDataWidgetConfigurations',
    }),
    async onDelete($event) {
      await this.removeData($event).then(async () => {
        await this.fetchDataWidgetConfigurations()
        await this.fetchDataDirectAccess()
      }).catch(error => {
        this.responseCatch(error)
      })
    },
  },

}
</script>
<style lang="scss" scoped>
.card-direct {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)); /* see notes below */
  grid-auto-rows: minmax(50px, auto);
  grid-gap: 1.5rem;
}
</style>
