// import store from '@/store'
/* eslint-disable */
// const userDataAux = JSON.parse(localStorage.getItem('vuex'))
// const userData = userDataAux.userAuthStore
// const idClub = userData.sport_clubs ? userData.sport_clubs.hash :  undefined

// const club = store.getters.clubInfo
const idClub = undefined
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      // ES
      // Administracion
      { title: 'Roles', route: { name: 'roles' }, icon: 'icon-0-icons-dark-folded', permission: ['VIEW_ROLES', 'ALLOW_ALL'], locale: 'es'},
      { title: 'Usuarios', route: { name: 'users' }, icon: 'icon-0-icons-dark-avatar', permission: ['VIEW_USERS', 'ALLOW_ALL'], locale: 'es' },
      { title: 'Puestos de trabajo', route: { name: 'job-position' }, icon: 'icon-0-icons-dark-badge', permission: ['VIEW_JOBS', 'ALLOW_ALL'], locale: 'es' },
      { title: 'Noticias', route: { name: 'new-posts' }, icon: 'icon-0-icons-dark-newsfeed', permission: ['VIEW_NOTICES', 'ALLOW_ALL'], locale: 'es' },
      
      // Acervo documental
      
      // Integrantes
      { title: 'Integrantes', route: { name: 'member' }, icon: 'icon-0-icons-dark-contacts', permission: ['MBERO', 'ALLOW_ALL'], locale: 'es' },
      { title: 'Equipos', route: { name: 'team' }, icon: 'icon-0-icons-dark-aim', permission: ['TAMSE', 'ALLOW_ALL'], locale: 'es' },
      
      // Clubs
      { title: 'Club', route: { path: `/clubs/` }, icon: 'icon-0-icons-dark-football', permission: ['ALLOW_ALL'], locale: 'es' },
      { title: 'Corporativo', route: { path: `/clubs/${idClub}/corporate` }, icon: 'icon-0-icons-dark-football', permission: ['VIEW_CORPORATE'], locale: 'es' },
      { title: 'Sociedad', route: { path: `/clubs/${idClub}/society` }, icon: 'icon-0-icons-dark-football', permission: ['VIEW_SOCIETY'], locale: 'es' },
      { title: 'Asambleas', route: { path: `/clubs/${idClub}/assemblies` }, icon: 'icon-0-icons-dark-football', permission: ['VIEW_ASSEMBLY'], locale: 'es' },
      { title: 'Poderes', route: { path: `/clubs/${idClub}/power-of-attorney` }, icon: 'icon-0-icons-dark-football', permission: ['VIEW_POWER_OF_ATTORNEY'], locale: 'es' },
      { title: 'Apoderados', route: { path: `/clubs/${idClub}/representative` }, icon: 'icon-0-icons-dark-football', permission: ['VIEW_ASSIGN_REPRESENTATIVES'], locale: 'es' },
      { title: 'Convenios', route: { path: `/clubs/${idClub}/intellectual-property/agreements` }, icon: 'icon-0-icons-dark-football', permission: ['AGMSE'], locale: 'es' },
      { title: 'Registro de marca', route: { path: `/clubs/${idClub}/intellectual-property/trademarks` }, icon: 'icon-0-icons-dark-football', permission: ['TDMKU'], locale: 'es' },
      { title: 'Registro de obra literaria o artistica', route: { path: `/clubs/${idClub}/intellectual-property/literary-or-artistic-works` }, icon: 'icon-0-icons-dark-football', permission: ['LOAWA'], locale: 'es' },

      // EN
      // Administracion
      { title: 'Roles', route: { name: 'roles' }, icon: 'icon-0-icons-dark-folded', permission: ['VIEW_ROLES', 'ALLOW_ALL'], locale: 'en'},
      { title: 'Users', route: { name: 'users' }, icon: 'icon-0-icons-dark-avatar', permission: ['VIEW_USERS', 'ALLOW_ALL'], locale: 'en' },
      { title: 'Job positions', route: { name: 'job-position' }, icon: 'icon-0-icons-dark-badge', permission: ['VIEW_JOBS', 'ALLOW_ALL'], locale: 'en' },
      { title: 'News', route: { name: 'new-posts' }, icon: 'icon-0-icons-dark-newsfeed', permission: ['VIEW_NOTICES', 'ALLOW_ALL'], locale: 'en' },

      // Acervo documental
      // Integrantes
      { title: 'Members', route: { name: 'member' }, icon: 'icon-0-icons-dark-contacts', permission: ['MBERO', 'ALLOW_ALL'], locale: 'en' },
      { title: 'Teams', route: { name: 'team' }, icon: 'icon-0-icons-dark-aim', permission: ['TAMSE', 'ALLOW_ALL'], locale: 'en' },
      
      // Clubs
      { title: 'Club', route: { path: `/clubs/` }, icon: 'icon-0-icons-dark-football', permission: ['ALLOW_ALL'], locale: 'en' },
      { title: 'Corporate', route: { path: `/clubs/${idClub}/corporate` }, icon: 'icon-0-icons-dark-football', permission: ['VIEW_CORPORATE'], locale: 'en' },
      { title: 'Aociety', route: { path: `/clubs/${idClub}/society` }, icon: 'icon-0-icons-dark-football', permission: ['VIEW_SOCIETY'], locale: 'en' },
      { title: 'Assemblies', route: { path: `/clubs/${idClub}/assemblies` }, icon: 'icon-0-icons-dark-football', permission: ['VIEW_ASSEMBLY'], locale: 'en' },
      { title: 'Power of attorney', route: { path: `/clubs/${idClub}/power-of-attorney` }, icon: 'icon-0-icons-dark-football', permission: ['VIEW_POWER_OF_ATTORNEY'], locale: 'en' },
      { title: 'Representative', route: { path: `/clubs/${idClub}/representative` }, icon: 'icon-0-icons-dark-football', permission: ['VIEW_ASSIGN_REPRESENTATIVES'], locale: 'en' },
      { title: 'Agreements', route: { path: `/clubs/${idClub}/intellectual-property/agreements` }, icon: 'icon-0-icons-dark-football', permission: ['AGMSE'], locale: 'en' },
      { title: 'Trademark Registration', route: { path: `/clubs/${idClub}/intellectual-property/trademarks` }, icon: 'icon-0-icons-dark-football', permission: ['TDMKU'], locale: 'en' },
      { title: 'Literary or Artistic Work Registration', route: { path: `/clubs/${idClub}/intellectual-property/literary-or-artistic-works` }, icon: 'icon-0-icons-dark-football', permission: ['LOAWA'], locale: 'en' },
    ],
  },
}
/* eslint-enable */
