<template>
  <b-card
    no-body
    class="business-card"
  >
    <b-card-header class="pb-1">
      <b-card-title> {{ $t('slopes') }} </b-card-title>

      <!-- Button -->
      <b-button
        v-if="checkPermission(['ALLOW_ALL', 'CREATE_TASK'])"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="btn-icon rounded-circle"
        :style="colorPrimaryBtn"
        @click="
          $router.push({
            name: 'todo',
          })
        "
      >
        <feather-icon
          icon="PlusIcon"
          size="18"
          class="cursor-pointer"
        />
      </b-button>
    </b-card-header>

    <b-card-body>
      <div v-if="checkPermission(['ALLOW_ALL', 'VIEW_TASK'])">
        <!-- height="220" -->
        <vue-apex-charts
          height="220"
          style="max-width: 411px;"
          :options="chartOptions"
          :series="[calculateTotalTasks]"
        />
      </div>
      <vue-perfect-scrollbar
        v-if="incompleteTasks.length > 0"
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <div
          v-if="checkPermission(['ALLOW_ALL', 'VIEW_TASK'])"
          class="business-items i-business-it"
        >
          <div
            v-for="todoItem in incompleteTasks"
            :key="todoItem.hash"
            class="business-item"
            style="padding: 5px 6px"
          >
            <div
              class="todo-task-list media-list"
              :class="{ 'completed': todoItem.is_completed }"
            >
              <div class="todo-title-wrapper">
                <div class="todo-title-area">
                  <div class="title-wrapper">
                    <b-form-checkbox
                      :checked="todoItem.is_completed"
                      :disabled="!checkPermission(['ALLOW_ALL', 'EDIT_TASK'])"
                      @click.native.stop
                      @change="updateTaskIsCompleted(todoItem)"
                    >
                      <small class="todo-title">{{ todoItem.title }}</small>
                    </b-form-checkbox>
                    <div class="d-flex align-items-center justify-content-end">
                      <b-badge
                        v-for="tag in todoItem.tags"
                        :key="tag.hash"
                        :variant="`light-${tag.color}`"
                        class="text-capitalize"
                      >
                        {{ tag.title }}
                      </b-badge>
                      <small class="text-nowrap text-muted ml-1">{{ formatDate(todoItem.due_date, { month: 'short', day: 'numeric'}) }}</small>
                    </div>
                  </div>
                </div>
              </div>
              <tasks-childrend
                v-if="checkPermission(['ALLOW_ALL', 'VIEW_TASK'])"
                :tasks.sync="todoItem.tasks"
                :check-permission="checkPermission"
                @task-is-completed="updateTaskIsCompleted"
              />
              <!-- @task-show="handleTaskClick" -->
            </div>
          </div>
        </div>
      </vue-perfect-scrollbar>
      <div
        v-else
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <h3 class="text-center mt-2">{{ $t('no-pending-to-overcome') }}</h3>
      </div>

      <!-- button -->

    </b-card-body>
  </b-card>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { formatDate } from '@core/utils/filter'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import TasksChildrend from '@/views/dashboard/TasksChildrend.vue'

export default {
  components: {
    VuePerfectScrollbar,
    VueApexCharts,
    TasksChildrend,
  },
  directives: {
    Ripple,
  },
  props: {
    todoList: {
      type: Array,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 50,
        wheelPropagation: false,
      },
      chartOptions: {
        series: [0],
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#e7e7e7',
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: $themeColors.primary,
                opacity: 1,
                blur: 2,
              },
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: '22px',
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        fill: {
          type: 'gradient',
          colors: [$themeColors.primary],
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 53, 98],
          },
        },
        labels: ['Average Results'],
      },
    }
  },
  computed: {
    calculateTotalTasks() {
      const completed = this.todoList.filter(item => item.is_completed === true)
      let total = Math.ceil((((100 / this.todoList.length) * completed.length)))
      if (Number.isNaN(total)) {
        total = 100
      }
      return total
    },
    incompleteTasks() {
      const incomplete = this.todoList.filter(item => item.is_completed === false)
      return incomplete
    },
  },
  methods: {
    formatDate,
    updateTaskIsCompleted(todoItem) {
      todoItem.is_completed = !todoItem.is_completed
      this.$emit('update-task', { task: todoItem })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/base/pages/app-todo.scss";

.scroll-area {
  position: relative;
  margin: auto;
  width: 99%;
  // min-height: 200px;
  height: 230px;
  // max-height: 330px;
}
.completed {
  .todo-title {
    text-decoration: line-through;
    color: lightgray;
  }
}
.business-items .business-item {
  padding-top: 8px;
  padding-right: 5px;
  padding-bottom: 8px;
  padding-left: 5px;
}
.badge {
  display: inline-block;
  font-size: 74%;
}
.business-card .business-items .business-item:not(:last-child) {
  margin-bottom: 0.1rem;
}

.i-business-it {
  padding: 0;
}
</style>
