<template>
  <b-overlay
    :show="isLoading"
  >
    <!-- :class="applyOverlayPOA ? 'p-2 mb-1' : 'mb-1'" -->
    <template #overlay>
      <div class="d-flex align-items-center justify-content-center mt-1">
        <b-spinner
          type="border"
          variant="primary"
        />
      </div>
      <div class="d-flex justify-content-center">
        <p class="pt-1">
          {{ $t('loading') }}
        </p>
      </div>
    </template>
    <b-card
      no-body
      class="card-developer-meetup"
    >
      <div class="rounded-top text-center mt-1">
        <h3>{{ $t('title-game-player') }}</h3>
        <label>{{ $t('sub-title-game-player') }}</label>
        <b-row>
          <b-col cols="12">
            <b-img
              :src="require('@/assets/images/dashboard/ball.png')"
              width="80"
              height="80"
              alt="OStrail image gamesPlayed"
            />
            <b class="centered">{{ percentage }}%</b>
          </b-col>
          <b-col cols="12">
            <b-card-text class="mb-0 mt-1">
              {{ $t('wins') }} {{ wins+'%' }}
            </b-card-text>
            <div class="progress-wrapper">
              <div>
                <i class="icon-0-icons-dark-checkmark wins" />
              </div>
              <div>
                <b-progress
                  v-model="wins"
                  class="progress-size progress-bar-success"
                  max="100"
                  variant="success"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <b-card-text class="mb-0 mt-1">
              {{ $t('draws') }} {{ draws+'%' }}
            </b-card-text>
            <div class="progress-wrapper">
              <div>
                <i class="icon-0-icons-dark-minus draws" />
              </div>
              <div>
                <b-progress
                  v-model="draws"
                  class="progress-size progress-bar-primary"
                  max="100"
                  variant="primary"
                />
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <b-card-text class="mb-0 mt-1">
              {{ $t('losses') }} {{ losses+'%' }}
            </b-card-text>
            <div class="progress-wrapper">
              <i class="icon-0-icons-dark-cross losses" />
              <b-progress
                v-model="losses"
                class="progress-size progress-bar-danger"
                max="100"
                variant="danger"
              />
            </div>
          </b-col>
        </b-row>
        <!-- <b-img
          :src="require('@/assets/images/dashboard/gamesPlayed.png')"
          width="220px"
          height="310"
          alt="OStrail image gamesPlayed"
        /> -->
      </div>
    <!-- <b-card-body>

    </b-card-body> -->
    </b-card>
  </b-overlay>
</template>

<script>

export default {
  name: 'GamesPlayed',
  components: {
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      wins: 70,
      draws: 10,
      losses: 20,
      percentage: '54',
    }
  },
}
</script>

<style lang="scss" scoped>
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wins {
  position: absolute;
  left: 12%;
  top: 60.77%;
  bottom: 79.96%;

  color: #80ff95;

}
.draws {
  position: absolute;
  left: 12%;
  top: 60.77%;
  bottom: 79.96%;
  color: #a3d1ff;
}
.losses {
  position: absolute;
  left: 12%;
  top: 66.77%;
  bottom: 79.96%;
  color: #cc296a;
  font-size: 12px;
}

.progress-size {
  width: 75%;
  float: right;
  margin-right: 15px;
}
</style>
