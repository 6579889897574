<template>
  <div>
    <div
      class="business-items i-business-it"
    >
      <div
        v-for="todoItem in tasks"
        :key="todoItem.hash"
        class="business-item"
        style="padding: 5px 6px"
      >
        <div
          class="todo-task-list media-list"
          :class="{ 'completed': todoItem.is_completed }"
        >
          <div class="todo-title-wrapper">
            <div class="todo-title-area">
              <div class="title-wrapper">
                <b-form-checkbox
                  :checked="todoItem.is_completed"
                  :disabled="!checkPermission(['ALLOW_ALL', 'EDIT_TASK'])"
                  @click.native.stop
                  @change="$emit('task-is-completed', todoItem)"
                >
                  <small class="todo-title">{{ todoItem.title }}</small>
                </b-form-checkbox>
                <div class="d-flex align-items-center justify-content-end">
                  <b-badge
                    v-for="tag in todoItem.tags"
                    :key="tag.hash"
                    :variant="`light-${tag.color}`"
                    class="text-capitalize"
                  >
                    {{ tag.title }}
                  </b-badge>
                  <small class="text-nowrap text-muted ml-1">{{ formatDate(todoItem.due_date, { month: 'short', day: 'numeric'}) }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { formatDate, avatarText } from '@core/utils/filter'

export default {
  props: {
    tasks: {
      required: true,
      type: Array,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      property: 'value',
    }
  },
  methods: {
    formatDate,
    avatarText,
  },
}
</script>
<style lang="css" scoped>
.i-business-it {
  padding: 1px 1px;
  margin-top: 5px;
}
</style>
