<template>
  <!-- <section id="dashboard-ecommerce"> -->
  <div style="width:100%;margin-top: 10px;height:100%; margin-bottom: 30px;">
    <b-overlay :show="isLoading">
      <!-- <div class="layoutJSON">
        Displayed as <code>[x, y, w, h]</code>:
        <div class="columns">
          <div
            v-for="item in layout"
            :key="item.i"
          >
            <b>{{ item.i }}</b>: [{{ item.x }}, {{ item.y }}, {{ item.w }}, {{ item.h }}]
          </div>
        </div>
      </div>
      <hr> -->
      <!-- :class="applyOverlayPOA ? 'p-2 mb-1' : 'mb-1'" -->
      <template #overlay>
        <div class="d-flex align-items-center justify-content-center mt-1">
          <b-spinner
            type="border"
            variant="primary"
          />
        </div>
        <div class="d-flex justify-content-center">
          <p class="pt-1">
            {{ $t('loading') }}
          </p>
        </div>
      </template>

      <grid-layout
        :layout.sync="layout"
        :responsive-layouts="userDashboard"
        :col-num="12"
        :row-height="30"
        :is-draggable="draggable"
        :is-resizable="resizable"
        :vertical-compact="true"
        :use-css-transforms="false"
        :responsive="responsive"
        :preserve-aspect-ratio="true"
        :breakpoints="breakP"
        :cols="colsLayout"
        @breakpoint-changed="breakpointChangedEvent"
      >
        <!-- :prevent-collision="true" -->
        <grid-item
          v-for="item in layout"
          :key="item.i"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :min-w="item.minW"
          :min-h="item.minH"
          :max-w="item.maxW"
          :max-h="item.maxH"
          drag-allow-from=".vue-draggable-handle"
          drag-ignore-from=".no-drag"
          @resized="resizedEvent"
          @moved="movedEvent"
        >
          <!-- {{ item }} -->

          <span
            class="remove"
            @click="handleDeleteItemLayout(item.i)"
          >x</span>
          <!-- Drag and drop support -->
          <div
            v-if="draggable"
            class="vue-draggable-handle"
          >
            <i class="icon-0-icons-dark-fullscreen" />
          </div>

          <!-- Clima -->
          <span v-if="item.i == '7'">
            <meta-weather-card @handle-loading="isLoading = $event" />
          </span>

          <!-- Note -->
          <span v-if="item.i == '8'">
            <todo-card
              :todo-list="todoList"
              :color-primary-btn="colorPrimaryBtn"
              :check-permission="checkPermission"
              @update-task="handleUpdateTask"
            />
          </span>

          <!-- Stadium -->
          <span v-if="item.i === '9'">
            <stadium-card />
          </span>

          <!-- Games Played -->
          <span v-if="item.i === '10'">
            <games-played />
          </span>

          <!-- TeamPerformance -->
          <span v-if="item.i === '11'">
            <team-performance-card :is-loading="isLoading" />
          </span>

          <!-- PlayerPerformance -->
          <span v-if="item.i === '12'">
            <player-performance-card />
          </span>

          <!-- Post new card -->
          <span v-if="item.i === '13'">
            <post-new-card />
          </span>

          <!-- Goals for card -->
          <span v-if="item.i === '14'">
            <goals-for-card />
          </span>

          <!-- Goals against card -->
          <span v-if="item.i === '15'">
            <goals-against-card />
          </span>

          <!-- tickets sale card -->
          <span v-if="item.i === '16'">
            <tickets-sale />
          </span>

          <span v-if="item.i === '17'">
            <direct-access-group-card
              :list-direct-access="listDirectAccess"
              :draggable.sync="draggable"
            />
          </span>
          <!-- :layout="layoutTest" -->

          <!-- Access -->
        </grid-item>
      </grid-layout>
    </b-overlay>
    <!-- Buttons -->
    <button-action-dashboard
      :show-modal="showModalAccessDirect"
      :show-modal-widget="showModalWidget"
      :color-primary-btn="colorPrimaryBtn"
      @handle-draggable="draggable = $event"
      @handle-resizable="resizable = $event"
      @handle-direct-access-modal="handleModal"
      @handle-widget-modal="handleModal"
    />

    <!-- modal access direction -->
    <direct-access-modal
      :show-modal-access-direct="showModalAccessDirect"
      :list-direct-access="listDirectAccess"
      :color-primary-btn="colorPrimaryBtn"
      @show-modal="handleModal"
      @add-direct-access="handleAddAccess"
    />

    <!-- Modal widget -->
    <widgets-modal
      v-if="widgetExists"
      :widget-list="widgetList"
      :user-widget-list="userDashboard"
      :show-modal-widget="showModalWidget"
      :color-primary-btn="colorPrimaryBtn"
      @show-modal="handleModal"
      @add-widget="handleAddWidget"
    />
  </div>

  <!-- </section> -->
</template>

<script>
import store from '@/store/index'
import { mapActions, mapGetters } from 'vuex'
import checkPermission from '@/auth/permissions'
// https://jbaysolutions.github.io/vue-grid-layout/guide/01-basic.html
import userService from '@/services/userService'
import VueGridLayout from 'vue-grid-layout'
import MetaWeatherCard from './MetaWeatherCard.vue'
import DirectAccessModal from './DirectAccessModal.vue'
import ButtonActionDashboard from './ButtonActionDashboard.vue'
import WidgetsModal from './WidgetsModal.vue'

// Test
// import DirectAccessCard from './widget/DirectAccessCard.vue'
import DirectAccessGroupCard from './widget/DirectAccessGroupCard.vue'
import TodoCard from './TodoCard.vue'
// import PostCard from './PostCard.vue'

import GamesPlayed from './widget/GamesPlayed.vue'
import PostNewCard from './widget/PostNewCard.vue'
import GoalsForCard from './widget/GoalsForCard.vue'
import GoalsAgainstCard from './widget/GoalsAgainstCard.vue'
import PlayerPerformanceCard from './widget/PlayerPerformanceCard.vue'
import TeamPerformanceCard from './widget/TeamPerformanceCard.vue'
import StadiumCard from './widget/StadiumCard.vue'
import TicketsSale from './widget/TicketsSale.vue'
// import ShortcutGroup from './widget/ShortcutGroup.vue'

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    // EcommerceCompanyTable,

    // DirectAccessCard,
    DirectAccessGroupCard,
    StadiumCard,
    MetaWeatherCard,
    DirectAccessModal,
    ButtonActionDashboard,
    WidgetsModal,

    // Test
    TodoCard,
    TeamPerformanceCard,
    PlayerPerformanceCard,
    GoalsForCard,
    GoalsAgainstCard,
    GamesPlayed,
    PostNewCard,
    TicketsSale,
    // ShortcutGroup,
  },
  data() {
    return {
      widgetExists: false,
      isLoading: false,
      data: {},
      showModalAccessDirect: false,
      showModalWidget: false,
      layouts: {
        lg: [],
        md: [],
        xxs: [],
      },
      layout: [],
      draggable: false,
      resizable: false,
      responsive: true,
      temp: {},
      layoutTest: [],
      sizeCols: 12,
      breakP: {
        xl: 1200,
        lg: 992,
        md: 768,
        sm: 576,
        xs: 480,
        xxs: 0,
      },
      colsLayout: {
        xl: 12, lg: 12, md: 12, sm: 12, xs: 12, xxs: 12,
      },
      userInfo: null,
    }
  },
  computed: {
    ...mapGetters({
      listDirectAccess: 'dashboard/directAccessList',
      widgetConfigurationsList: 'dashboard/widgetConfigurationsList',
      todoList: 'todo/listFilter',
      currentBreakPoint: 'app/currentBreakPoint',
      // widgetList: 'widget/list',
      userDashboard: 'dashboard',
      userId: 'id',
      colorPrimaryBtn: 'colorPrimaryBtn',
      // directAccessConfigurations: 'dashboard/directAccessConfigurations',
      directAccessConfigurations: 'directAccessConfigurations',
    }),
    widgetList() {
      const dataList = this.widgetConfigurationsList
      dataList.lg.forEach(item => {
        const filterStatus = this.userDashboard.lg.find(itemW => itemW.i == item.i)
        item.status = filterStatus != null ? 1 : 0
      })
      dataList.lg = dataList.lg.filter(item => item.title != null)
      dataList.md = dataList.md.filter(item => item.title != null)
      dataList.xxs = dataList.xxs.filter(item => item.title != null)
      return dataList
    },
  },
  created() {
    this.validBreakpoints()
  },
  mounted() {
    if (checkPermission(['VIEW_DASHBOARD'])) {
      this.catalogs()
      this.getUserAuth()
    } else {
      this.$router.push({ name: 'account-settings', params: { id: store.state.userAuthStore.id } })
    }
  },
  methods: {
    checkPermission,
    ...mapActions({
      fetchDataDirectAccess: 'dashboard/fetchDataDirectAccess',
      fetchDataWidgetConfigurations: 'dashboard/fetchDataWidgetConfigurations',
      addData: 'dashboard/addDirectAccess',
      removeData: 'dashboard/removeDirectAccess',

      // Dasboard
      updateWidgetConfiguration: 'dashboard/makeUpdateWidgetConfiguration',

      // Pending
      // fetchTasks: 'todo/fetchTasks',
      getTasksByDate: 'todo/getTasksByDate',
      updateTask: 'todo/updateTask',
      removeTask: 'todo/removeTask',
    }),
    async catalogs() {
      await this.fetchDataWidgetConfigurations().then(async response => {
        // await this.validBreakpoints()
      }).catch(error => {
        this.responseCatch(error)
      })
      // })
      await this.fetchDataDirectAccess()
      await this.getTasksByDate()
      this.widgetExists = true
    },
    breakpointChangedEvent(newBreakpoint, newLayout) {
    },
    validBreakpoints() {
      if (this.currentBreakPoint === 'xs') {
        // this.layout = layoutElements.xxs
        this.layout = this.userDashboard.xxs
      }
      if (this.currentBreakPoint === 'md' || this.currentBreakPoint === 'sm') {
        // this.layout = layoutElements.md
        this.layout = this.userDashboard.md
      }
      if (this.currentBreakPoint === 'lg') {
        // this.layout = layoutElements.lg
        this.layout = this.userDashboard.lg
      }
      if (this.currentBreakPoint === 'xl') {
        // this.layout = layoutElements.lg
        this.layout = this.userDashboard.lg
      }
    },
    handleModal(pValue, applyAccessDirect) {
      if (applyAccessDirect) {
        this.showModalAccessDirect = pValue
      } else {
        this.showModalWidget = pValue
      }
      this.draggable = false
      this.resizable = false
    },
    async handleAddAccess(dataAccess) {
      const idItem = Date.now()
      dataAccess.layout_id = idItem
      await this.addData(dataAccess).then(response => {
        this.getUserAuth()
      }).catch(error => {
        this.responseCatch(error)
      })
      await this.fetchDataWidgetConfigurations()
      await this.fetchDataDirectAccess()
    },
    async handleUpdateTask(todoItem) {
      await this.updateTask(todoItem).then(response => {
        // this.deleteTask(todoItem.task)
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    deleteTask(task) {
      this.removeTask(task.hash).then(response => {
        this.getTasksByDate()
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    // Test
    movedEvent(i, newX, newY) {
      const updateWidgetPosition = this.handleUpdateItemLayout(this.userDashboard)
      this.handleAddWidget(updateWidgetPosition)
    },
    resizedEvent(i, newX, newY, newHPx, newWPx) {
      const msg = `RESIZED i=${i}, X=${newX}, Y=${newY}, H(px)=${newHPx}, W(px)=${newWPx}`
      const updateWidgetPosition = this.handleUpdateItemLayout(this.userDashboard)
      this.handleAddWidget(updateWidgetPosition)
    },
    handleUpdateItemLayout(widgetPosition) {
      if (this.currentBreakPoint === 'xs') {
        widgetPosition.xxs = this.layout
      }
      if (this.currentBreakPoint === 'md' || this.currentBreakPoint === 'sm') {
        widgetPosition.md = this.layout
      }
      if (this.currentBreakPoint === 'lg') {
        widgetPosition.lg = this.layout
      }
      if (this.currentBreakPoint === 'xl') {
        widgetPosition.lg = this.layout
      }
      return widgetPosition
    },
    // TODO:change
    async handleAddWidget(widget) {
      // widget.hash = this.userDashboard.hash
      await this.updateWidgetConfiguration(widget).then(response => {
        this.validBreakpoints()
        // this.fetchDataWidget()
        this.fetchDataDirectAccess()
        this.getUserAuth()
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    getUserAuth() {
      const data = { locale: null }
      userService.getUserAuthenticated(data).then(response => {
        this.userInfo = response.data.data
        store.dispatch('setInfo', { user: this.userInfo, permissions: null })
      }).catch(error => {
        console.error(error)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
<style scoped>
.vue-grid-item {
  /* transition: all 200ms ease; */
  /* transition: none; */
}

.vue-grid-layout {
  /* transition: height 200ms ease; */
  /* transition: none; */
}

.vue-grid-layout {
  /* background: #eee; */
}

.vue-grid-item:not(.vue-grid-placeholder) {
  /* background: #ccc; */
  /* border: 1px solid black; */
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-draggable-handle {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 0;
  left: 0;
  /* padding: 0 8px 8px 0; */
  /* background-origin: content-box; */
  background-color: #59637f;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: move;
  z-index: 1;
  color: white;
  font-size: 13px;
  padding-left: 2px;
  padding-top: 2px;
}

.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}

.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}

.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
</style>
