<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <div class="bg-light-primary rounded-top image-news text-center">
      <!-- width="220px" -->
      <!-- height="140" -->
      <b-img
        class="image-news"
        :src="require('@/assets/images/dashboard/hederNew.png')"
        alt="OStrail image postNew"
      />
    </div>
    <b-card-body>
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center">
        <div class="my-auto">
          <!-- <b-card-title class="mb-25">
          </b-card-title> -->
          <b class="text-center">{{ title.substring(0,45)+"..."}}</b>
          <label class="mb-0">
            {{description.substring(0,75)+"..." }}
          </label>
        </div>
      </div>

    </b-card-body>
  </b-card>
</template>

<script>

export default {
  name: 'PostNewCard',
  components: {
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      title: 'Comienza una nueva temporada de Champions League',
      description: 'La ceremonia del sorteo de la fase de grupos de la UEFA Champions League 2022/23 comienza el jueves 26 de agosto.',
    }
  },
}
</script>
<style lang="scss" scoped>
.image-news {
  width: 100%;
  height: 100%
}
</style>
