<template>
  <b-card
    no-body
    class="card-developer-meetup image-stadium"
  >
    <b-row class="align-items-center">
      <b-col
        cols="12"
        sm="7"
        md="7"
        lg="7"
        xl="7"
        class="text-center"
      >
        <div class="images">
          <b-img
            :src="require('@/assets/images/dashboard/stadium.png')"
            alt="OStrail image stadium"
          />
        </div>
        <!-- <stadium class="images" /> -->
      </b-col>
      <b-col
        cols="12"
        sm="5"
        md="5"
        lg="5"
        xl="5"
        class="text-white"
      >
        <b-card-header>
          <blockquote class="blockquote mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-img
                  rounded="circle"
                  :src="teamOne.club_image_file"
                  alt="Generic placeholder image"
                  height="30"
                  width="30"
                />
              </b-media-aside>
              <b-media-body>
                {{ teamOne.club_name }}
                <!-- <span> {{ teamOne.goals }} </span> -->
              </b-media-body>
            </b-media>
            <small class="text-white float-right">{{ teamOne.numberJersey }}</small>
          </blockquote>
          <!-- <div class="text-nowrap"> -->
          <h3 class="text-white d-sm-none d-md-none d-lg-block">
            {{ teamOne.goals }} : {{ teamTwo.goals }}
          </h3>
          <!-- </div> -->
          <blockquote class="blockquote mt-1">
            <b-media no-body>
              <b-media-body>
                <!-- <span> {{ teamTwo.goals }} </span> -->
                {{ teamTwo.club_name }}
              </b-media-body>
              <b-media-aside class="ml-1">
                <b-img
                  rounded="circle"
                  :src="teamTwo.club_image_file"
                  alt="Generic placeholder image"
                  height="30"
                  width="30"
                />
              </b-media-aside>
            </b-media>
            <small class="text-white float-left">{{ teamTwo.numberJersey }}</small>
          </blockquote>
          <h3 class="text-white text-center w-100 d-none d-sm-block d-md-block d-lg-none">
            {{ teamOne.goals }} : {{ teamTwo.goals }}
          </h3>
        </b-card-header>
        <b-card-body class="text-center">
          <dl class="row m-0">
            <dt class="col-sm-3 col-3 text-right">
              {{ teamOne.raiting }}
            </dt>
            <dd class="col-sm-6 col-6 col-6">
              {{ $t('rating') }}
            </dd>
            <dt class="col-sm-3 col-3 text-left">
              {{ teamTwo.raiting }}
            </dt>
          </dl>
          <hr class="m-0">
          <dl class="row m-0">
            <dt class="col-sm-3 col-3 text-right">
              {{ teamOne.shots }}
            </dt>
            <dd class="col-sm-6 col-6">
              {{ $t('shots') }}
            </dd>
            <dt class="col-sm-3 col-3 text-left">
              {{ teamTwo.shots }}
            </dt>
          </dl>
          <hr class="m-0">
          <dl class="row m-0">
            <dt class="col-sm-3 col-3 text-right">
              {{ teamOne.possession }}
            </dt>
            <dd class="col-sm-6 col-6">
              {{ $t('possession') }}
            </dd>
            <dt class="col-sm-3 col-3 text-left">
              {{ teamTwo.possession }}
            </dt>
          </dl>
          <hr class="m-0">
          <dl class="row m-0">
            <dt class="col-sm-3 col-3 text-right">
              {{ teamOne.successfulPasses }}
            </dt>
            <dd class="col-sm-6 col-6">
              {{ $t('successful-passes') }}
            </dd>
            <dt class="col-sm-3 col-3 text-left">
              {{ teamTwo.successfulPasses }}
            </dt>
          </dl>
          <hr class="m-0">
          <dl class="row m-0">
            <dt class="col-sm-3 col-3 text-right">
              {{ teamOne.dodge }}
            </dt>
            <dd class="col-sm-6 col-6">
              {{ $t('dodge') }}
            </dd>
            <dt class="col-sm-3 col-3 text-left">
              {{ teamTwo.dodge }}
            </dt>
          </dl>
          <hr class="m-0">
          <dl class="row m-0">
            <dt class="col-sm-3 col-3 text-right">
              {{ teamOne.aerialMoves }}
            </dt>
            <dd class="col-sm-6 col-6">
              {{ $t('aerial-plays') }}
            </dd>
            <dt class="col-sm-3 col-3 text-left">
              {{ teamTwo.aerialMoves }}
            </dt>
          </dl>
          <hr class="m-0">
          <dl class="row m-0">
            <dt class="col-sm-3 col-3 text-right">
              {{ teamOne.tackles }}
            </dt>
            <dd class="col-sm-6 col-6">
              {{ $t('tackles') }}
            </dd>
            <dt class="col-sm-3 col-3 text-left">
              {{ teamTwo.tackles }}
            </dt>
          </dl>
          <hr class="m-0">
          <dl class="row m-0">
            <dt class="col-sm-3 col-3 text-right">
              {{ teamOne.cornerKicks }}
            </dt>
            <dd class="col-sm-6 col-6">
              {{ $t('corner-kicks') }}
            </dd>
            <dt class="col-sm-3 col-3 text-left">
              {{ teamTwo.cornerKicks }}
            </dt>
          </dl>
          <hr class="m-0">
          <dl class="row m-0">
            <dt class="col-sm-3 col-3 text-right">
              {{ teamOne.losses }}
            </dt>
            <dd class="col-sm-6 col-6">
              {{ $t('losses-widget') }}
            </dd>
            <dt class="col-sm-3 col-3 text-left">
              {{ teamTwo.losses }}
            </dt>
          </dl>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
// import Stadium from './components/Stadium.vue'

export default {
  components: {
    // Stadium,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      teamOne: {
        club_image_file: require('@/assets/images/clubs/fcb.png'),
        club_name: 'Barcelona',
        raiting: 6.8,
        shots: 23,
        possession: 60,
        successfulPasses: 76,
        dodge: 6,
        aerialMoves: 13,
        tackles: 4,
        cornerKicks: 2,
        losses: 10,
        goals: '5',
        numberJersey: '3-2',
      },
      teamTwo: {
        club_image_file: require('@/assets/images/clubs/athletic.png'),
        club_name: 'Athletic',
        raiting: 7.6,
        shots: 15,
        possession: 20,
        successfulPasses: 76,
        dodge: 6,
        aerialMoves: 12,
        tackles: 5,
        cornerKicks: 3,
        losses: 12,
        goals: '3',
        numberJersey: '1-2',
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.image-stadium {
  background-image: linear-gradient(to top right,#90ECD1 0%, #00CFC3);
}
.images {
    background-color: #eee;
    overflow-x: scroll;
}

@media (min-width: 960px) {

  .images {
     overflow-x: visible;
  }
}
@media (max-width: 576px) {
  .images img {
    width: 500px;
  }
}
</style>
